<template>
  <div>
    <AutoScbForm v-if="bankAccountForm.bankCode === BankCodeEnum.SCB" :bank-account-form="bankAccountForm" />
    <AutoBayForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.BAY" :bank-account-form="bankAccountForm" />
    <AutoKBankForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.KBANK" :bank-account-form="bankAccountForm" />
    <AutoKkpForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.KKP" :bank-account-form="bankAccountForm" />
    <AutoKtbForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.KTB" :bank-account-form="bankAccountForm" />
    <AutoGsbForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.GSB" :bank-account-form="bankAccountForm" />
    <AutoTtbForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.TTB" :bank-account-form="bankAccountForm" />
    <AutoTrueWalletForm v-else-if="bankAccountForm.bankCode === BankCodeEnum.TRUE_WALLET" :bank-account-form="bankAccountForm" />
    <div v-else>
      <b-alert show variant="danger">
        <h4 class="alert-heading">
          ธนาคารนี้ไม่รองรับการเชื่อมต่อระบบออโต้
        </h4>
        <hr />

        ธนาคารที่รองรับ
        <ul>
          <li v-for="(bankCode, i) in SupportedAutoBanking" :key="i">
            {{ getBankName(bankCode) }}
          </li>
        </ul>
      </b-alert>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {BankCodeEnum, BankUseTypeEnum, SupportedAutoBanking} from '@src/constants/bank';
import {banks} from '@utils/data-mock';

export default Vue.extend({
  name: 'AutoBankingForm',
  components: {
    AutoScbForm: () => import('./auto-scb/form'),
    AutoKBankForm: () => import('./auto-kbank/form'),
    AutoBayForm: () => import('./auto-bay/form'),
    AutoKkpForm: () => import('./auto-kkp/form'),
    AutoKtbForm: () => import('./auto-ktb/form'),
    AutoGsbForm: () => import('./auto-gsb/form'),
    AutoTtbForm: () => import('./auto-ttb/form'),
    AutoTrueWalletForm: () => import('./auto-true-wallet/form'),
  },
  props: {
    bankAccountForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    SupportedAutoBanking() {
      return SupportedAutoBanking
    },
    BankCodeEnum() {
      return BankCodeEnum
    },
    BankUseTypeEnum() {
      return BankUseTypeEnum
    },
  },
  methods: {
    getBankName(bankCode) {
      return banks
        .filter((bank) => process.env.VUE_APP_IS_LA === 'true' || bank.bank_code !== BankCodeEnum.BCEL)
        .find((bank) => bank.bank_code === bankCode)
        .bank_name_th
    }
  },
})
</script>

<style scoped>

</style>